/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserData } from 'hooks/UserContext';
import TextField from '@mui/material/TextField';
import Flow from '../../components/flow';
import FlowContext from '../../components/flow/context';
import { searchProfileStepPages } from '../../components/flow/steps/searchProfile';
import PhoneInput from '../../components/phone-input';
import ValueSelector from '../../components/selector';
import SchoolFinder from '../../components/school-finder/src/SchoolFinder';
import EmptyExperience from './steps/empty';
import WarningExperience from './steps/warning';
import Congrats from './steps/congrats';
import paths from '../../../../../routes/paths';
import useExperience from '../../hooks/useExperience';
import { onResolveOptions } from '../../helpers/flowUtils';

const ExperienceCreator = () => {
  const navigate = useNavigate();
  const {
    activeExperience, setIsLoginModalOpen, userData,
  } = useUserData();

  const [newExperience, setNewExperience] = useState({});

  const { finishedLoading, experienceToWarn, ...optionData } = useExperience();
  const [showWarning, setShowWarning] = useState();
  const [showEmpty, setShowEmpty] = useState(true);
  const [showCongrats, setShowCongrats] = useState(false);
  const [currentPageId, setCurrentPageId] = useState();

  useEffect(() => {
    if (activeExperience && showEmpty) {
      navigate(paths.EXPERIENCE_RECOMMENDATIONS);
    }
    if (typeof window.Tawk_API?.hideWidget === 'function') window.Tawk_API.hideWidget();

    return () => {
      if (typeof window.Tawk_API?.showWidget === 'function') window.Tawk_API.showWidget();
    };
  }, []);

  useEffect(() => {
    if (currentPageId) {
      window.history.replaceState(null, '', `${window.location.pathname}?page=${currentPageId}`);
    }
  }, [currentPageId]);

  useEffect(() => {
    setShowWarning(!!experienceToWarn);
  }, [experienceToWarn]);

  const calculateOnlyPreschoolUpdate = (levels, currentExp) => {
    const currentOnlyPreschool = currentExp.onlyPreschool;
    const computedOnlyPreschool = !!levels?.every(
      ({ value: selectedLevelId }) => !!optionData.level?.find(
        ({ value: levelId, ageNumber }) => levelId === selectedLevelId && ageNumber <= 5,
      ),
    );
    if ((currentOnlyPreschool && !computedOnlyPreschool)
      || (!currentOnlyPreschool && computedOnlyPreschool)) {
      return computedOnlyPreschool;
    }
    return null;
  };

  const calculateOnlyPublicUpdate = (publics, currentExp) => {
    const currentOnlyPublicSchools = currentExp.onlyPublicSchools;
    const computedOnlyPublicSchools = !!publics?.every(
      (selectedPublicId) => !!optionData.typeSchool?.find(
        ({ value: publicId, isPublic }) => publicId === selectedPublicId && isPublic,
      ),
    );
    if ((currentOnlyPublicSchools && !computedOnlyPublicSchools)
      || (!currentOnlyPublicSchools && computedOnlyPublicSchools)) {
      return computedOnlyPublicSchools;
    }
    return null;
  };

  const onChangeStepValue = (key, value, currentExp, merge) => {
    // console.log('onChangeStepValue', key, value, currentExp, merge);
    const update = { };
    if (key === 'level') {
      const newOnlyPreschool = calculateOnlyPreschoolUpdate(value, currentExp);
      if (typeof newOnlyPreschool === 'boolean') {
        update.onlyPreschool = newOnlyPreschool;
      }
    }
    if (key === 'typeSchool') {
      const newOnlyPublicSchools = calculateOnlyPublicUpdate(value, currentExp);
      if (typeof newOnlyPublicSchools === 'boolean') {
        update.onlyPublicSchools = newOnlyPublicSchools;
      }
    }
    if (key === 'costs' && value?.initCost) {
      const next = document.getElementById('schoolCost');
      if (next) {
        next.scrollIntoView({ behavior: 'smooth' });
      }
    }
    if (key === 'address' && value?.addressTxt && !currentExp?.address?.range) {
      const next = document.getElementById('distance-slider');
      if (next) {
        setTimeout(() => {
          next.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
      }
    }
    setNewExperience((prevExperience) => ({
      ...prevExperience,
      [key]: merge ? { ...(prevExperience[key] ?? {}), ...value } : value,
      ...update,
    }));
  };

  const buildSelectedAgeNumbersList = (exp, levelOptions) => {
    const { level: selectedLevels } = exp;
    return selectedLevels?.map(({ value: ageId }) => {
      const optionForLevel = levelOptions.find((option) => option.value === ageId);
      return optionForLevel?.ageNumber;
    });
  };

  const steps = [
    {
      id: 'newExperienceStep',
      // title: 'Información básica',
      pages: [
        {
          id: 'year',
          header: 'Información básica',
          title: '¿Para cuándo estás buscando centro educativo?',
          Component: (currentData) => (
            <ValueSelector
              options={onResolveOptions('year', optionData, currentData)}
              type="single"
              onChange={(value) => onChangeStepValue('year', value)}
            />
          ),
        },
        {
          id: 'level',
          header: 'Información básica',
          title: '¿En qué nivel educativo ingresarían tus hijos?',
          Component: (currentData) => (
            <ValueSelector
              options={onResolveOptions('level', optionData, currentData)}
              type="multy"
              onChange={(value) => onChangeStepValue('level', value, currentData)}
            />
          ),
        },
        {
          id: 'schoolRecomended',
          header: 'Tus candidatos',
          title: '¿Tenés centros educativos que ya estés considerando?',
          subtitle: 'Agregá a tu análisis los centros que te hayan recomendado amigos o familiares.',
          valid: () => true,
          Component: (exp) => (
            <SchoolFinder
              onChange={(value) => onChangeStepValue('schoolRecomended', value)}
              // eslint-disable-next-line react/destructuring-assignment
              existing={exp.schoolRecomended || []}
              ageNumbersList={buildSelectedAgeNumbersList(exp, optionData.level)}
            />
          ),
        },
        ...searchProfileStepPages(optionData, onChangeStepValue, 'Perfil de búsqueda'),
        {
          id: 'receiveOpportunities',
          header: 'Oportunidades educativas',
          title: '¿Te interesaría recibir oportunidades de centros que puedan adaptarse a tu capacidad de pago?',
          subtitle: 'Los centros educativos nos comparten periódicamente vacantes que podrían ajustarse a tu perfil de búsqueda.',
          skip: ({ onlyPublicSchools }) => !!onlyPublicSchools,
          valid: (exp) => exp?.receiveOpportunities
            && (exp.receiveOpportunities.value === '0' || (exp.receiveOpportunitiesData?.fullName && exp.receiveOpportunitiesData?.phoneData?.isValid)),
          Component: (exp) => (
            <>
              <ValueSelector
                options={onResolveOptions('receiveOpportunities', {
                  receiveOpportunities: [
                    {
                      label: 'Me interesa',
                      value: '1',
                      name: 'receiveOpportunities',
                      componentWhenSelected: (
                        <>
                          <span className="inside-component__title">
                            Para comunicarte las oportunidades necesitamos los siguientes datos:
                          </span>
                          <TextField
                            label="Nombre completo"
                            name="fullName"
                            variant="outlined"
                            size="medium"
                            value={exp.receiveOpportunitiesData?.fullName ?? ''}
                            onChange={(event) => {
                              onChangeStepValue('receiveOpportunitiesData', { fullName: event.target.value }, exp, true);
                            }}
                            error={
                              exp.receiveOpportunitiesData
                              && !exp.receiveOpportunitiesData.fullName
                            }
                            helperText={
                              exp.receiveOpportunitiesData
                              && !exp.receiveOpportunitiesData.fullName ? 'El nombre es requerido' : ''
                            }
                          />
                          <PhoneInput
                            label="Teléfono celular"
                            data={exp.receiveOpportunitiesData?.phoneData}
                            setData={(newData) => onChangeStepValue('receiveOpportunitiesData', { phoneData: newData }, exp, true)}
                            errorStatus={
                              exp.receiveOpportunitiesData?.phoneData
                              && !exp.receiveOpportunitiesData.phoneData?.isValid
                            }
                          />
                        </>
                      ),
                    },
                    {
                      label: 'No me interesa',
                      value: '0',
                      name: 'receiveOpportunities',
                    },
                  ],
                }, exp)}
                type="single"
                onChange={(value) => onChangeStepValue('receiveOpportunities', value)}
              />
            </>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    // Set default langs
    if (optionData.languages?.length && !newExperience.languages?.length) {
      const nativeLangsIds = optionData.languages.filter(
        ({ isNative }) => !!isNative,
      ).map(({ value }) => value);
      setNewExperience({
        languages: nativeLangsIds,
        languagesClass: nativeLangsIds,
      });
    }
  }, [optionData.languages]);

  useEffect(() => {
    if (newExperience) {
      // console.log('newExperience2', newExperience);
    }
  }, [newExperience]);

  return (
    <FlowContext.Provider
      value={{
        isFlow: true,
        currentData: newExperience,
        setCurrentPageId,
        steps,
        onEnd: () => setShowCongrats(true),
        onExit: showCongrats ? null : () => navigate('/'),
        backActionOnFirstPage: () => setShowEmpty(true),
      }}
    >
      {showWarning && !userData?.loginStatus && showEmpty && (
        <WarningExperience
          next={() => setShowWarning(false)}
          login={() => setIsLoginModalOpen(true)}
          experienceData={experienceToWarn}
        />
      )}
      {!(showWarning && !userData?.loginStatus) && showEmpty && (
        <EmptyExperience onStart={finishedLoading ? () => setShowEmpty(false) : null} />
      )}
      {(!showEmpty && !showCongrats) && (
        <Flow />
      )}
      {showCongrats && <Congrats optionData={optionData} />}
    </FlowContext.Provider>
  );
};

export default ExperienceCreator;
