/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Slider from '@mui/material/Slider';

import { onResolveOptions } from '../../../helpers/flowUtils';
import ValueSelector from '../../selector';
import AddressSelector from '../../address-selector';

const languagesClassInfoTemplate = {
  0: () => '',
  1: (languages) => `en ${languages[0]}`,
  2: (languages) => `bilingüe en ${languages[0]} - ${languages[1]}`,
  3: (languages) => `trilingüe en ${languages[0]} - ${languages[1]} - ${languages[2]}`,
};

const resolveLanguagesClassInfo = (exp, optionData) => {
  const { languagesClass: selectedClassLangs, languages: allLangs } = exp;
  const extraLangs = allLangs.filter(
    (lang) => !selectedClassLangs.some((selected) => selected === lang),
  );

  const selectedLangsLabels = optionData.languages
    ?.filter((leng) => selectedClassLangs.some((selected) => selected === leng.value))
    ?.map((leng) => leng.label.toLowerCase()) || [];
  const selectedExtraLangsLabels = optionData.languages
    ?.filter((leng) => extraLangs.some((selected) => selected === leng.value))
    ?.map((leng) => leng.label.toLowerCase()) || [];

  const start = languagesClassInfoTemplate[selectedClassLangs.length](selectedLangsLabels);

  return (
    <span>
      Priorizaremos centros que brinden educación
      {' '}
      <b>{start}</b>
      {' '}
      {selectedExtraLangsLabels.length
        ? (
          <>
            y tengan
            {' '}
            <b>
              horas de estudio de
              {' '}
              {selectedExtraLangsLabels.join(' - ')}
            </b>
          </>
        ) : null}
      .
    </span>
  );
};

const resolveLenguagesClassOptions = (exp, optionData) => {
  const { languages: selectedLanguageIds } = exp;
  const classLangOptions = optionData.languages
    ?.filter((leng) => selectedLanguageIds.some((langId) => langId === leng.value)) || [];

  const newOptions = classLangOptions.map((leng) => ({
    ...leng,
    checked: !leng.disabled ? false : leng.checked,
    name: 'languagesClass',
  }));

  return onResolveOptions('languagesClass', { languagesClass: newOptions }, exp);
};

export const searchProfileStepPages = (optionData, onChangeStepValue, header) => ([
  {
    id: 'typeSchool',
    header,
    title: '¿Qué tipo de institución estás buscando?',
    Component: (exp) => (
      <ValueSelector
        options={onResolveOptions('typeSchool', optionData, exp)}
        type="multy"
        subtitle="Podés seleccionar más de una opción."
        onChange={(value) => onChangeStepValue('typeSchool', value, exp)}
      />
    ),
  },
  {
    id: 'acceptsPrivateSuggestions',
    header,
    title: '¿Te interesaría recibir por e-mail oportunidades en centros privados que puedan adaptarse a tu capacidad de pago?',
    subtitle: 'Tu respuesta no afectará las recomendaciones de esta búsqueda.',
    skip: ({ onlyPublicSchools }) => !onlyPublicSchools,
    valid: (exp) => exp?.acceptsPrivateSuggestions && (exp.acceptsPrivateSuggestions.value === '0' || exp.acceptsPrivateSuggestionsBudget),
    Component: (exp) => (
      <>
        <ValueSelector
          options={onResolveOptions('acceptsPrivateSuggestions', optionData, exp)}
          type="single"
          onChange={(value) => onChangeStepValue('acceptsPrivateSuggestions', value)}
        />
        {exp.acceptsPrivateSuggestions?.value === '1' && (
          <div className="budget-container">
            <span className="flow-page__title budget-title">Selecciona tu presupuesto mensual máximo por hijo</span>
            <span className="flow-page__subtitle budget-subtitle">Monto en Pesos Uruguayos</span>
            <Slider
              className={`budget__slider flow__slider ${!exp.acceptsPrivateSuggestionsBudget ? 'invalid' : ''}`}
              aria-label="Presupuesto máximo"
              // defaultValue={0}
              value={exp.acceptsPrivateSuggestionsBudget ?? 0}
              onChange={(_event, value) => onChangeStepValue('acceptsPrivateSuggestionsBudget', value)}
              getAriaValueText={(value) => `$ ${value.toLocaleString('es-UY')}`}
              valueLabelFormat={(value) => `$ ${value.toLocaleString('es-UY')}`}
              valueLabelDisplay="on"
              step={1000}
              min={0}
              max={25000}
              marks={[
                { value: 0, label: '0' },
                { value: 5000, label: '5.000' },
                { value: 10000, label: '10.000' },
                { value: 15000, label: '15.000' },
                { value: 20000, label: '20.000' },
                { value: 25000, label: '25.000' },
                // { value: 30000, label: '30.000' },
              ]}
            />
          </div>
        )}
      </>
    ),
  },
  {
    id: 'preschoolOrSchool',
    header,
    title: '¿Qué tipo de centro de educación inicial estás buscando?',
    skip: ({ onlyPreschool, onlyPublicSchools }) => !onlyPreschool || onlyPublicSchools,
    Component: (exp) => (
      <ValueSelector
        options={onResolveOptions('preschoolOrSchool', optionData, exp)}
        onChange={(value) => onChangeStepValue('preschoolOrSchool', value)}
        info={exp.preschoolOrSchool?.value
          ? (
            <span>
              En las recomendaciones priorizaremos
              {' '}
              <b>{exp.preschoolOrSchool.value === 'school' ? 'centros educativos que incluyan primaria' : 'jardines de infantes'}</b>
              .
            </span>
          )
          : null}
      />
    ),
  },
  {
    id: 'religion',
    header,
    title: '¿Preferís que sea una institución religiosa o laica?',
    skip: ({ onlyPublicSchools }) => !!onlyPublicSchools,
    valid: (exp) => exp?.religion && !(exp.religion.value === 'yes' && !exp.religion.list?.length),
    Component: (exp) => (
      <ValueSelector
        key="religion"
        options={onResolveOptions('religion', optionData, exp)}
        type="single"
        onChange={(value) => onChangeStepValue('religion', value)}
          // eslint-disable-next-line react/destructuring-assignment
        info={exp.religion?.value === 'yes'
          ? 'En el listado se muestran únicamente las religiones que se ofrecen en el mercado según nuestra base de datos.'
          : null}
      />
    ),
  },
  {
    id: 'languages',
    header,
    title: '¿Qué idiomas debería enseñar el centro educativo?',
    skip: ({ onlyPublicSchools }) => !!onlyPublicSchools,
    Component: (exp) => (
      <ValueSelector
        key="languages"
        subtitle="Seleccioná hasta 3 más de la lista."
        options={onResolveOptions('languages', optionData, exp)}
        type="multy"
        onChange={(value) => onChangeStepValue('languages', value)}
        maxOptions={4}
        info="En el listado se muestran únicamente los idiomas que se ofrecen en el mercado según nuestra base de datos."
      />
    ),
  },
  {
    id: 'languagesClass',
    header,
    title: 'De los idiomas seleccionados en el paso anterior, ¿en cuáles se tienen que dictar las clases?',
    skip: ({ languages, onlyPublicSchools }) => (
      !!onlyPublicSchools || languages?.length === 1
    ),
    Component: (exp) => (
      <ValueSelector
        key="languagesClass"
        subtitle={exp?.languages?.length === 4 ? 'Seleccioná hasta 2 más de la lista.' : null}
        options={resolveLenguagesClassOptions(exp, optionData)}
        type="multy"
        info={resolveLanguagesClassInfo(exp, optionData)}
        onChange={(value) => onChangeStepValue('languagesClass', value)}
        maxOptions={3}
      />
    ),
  },
  {
    id: 'costs',
    header,
    title: '¿Cuanto invertirías mensualmente en la educación de un hijo?',
    skip: ({ onlyPublicSchools }) => !!onlyPublicSchools,
    valid: ({ onlyPreschool, costs }) => {
      if (onlyPreschool && !costs?.initCost) {
        return false;
      }
      if (!costs?.schoolCost) {
        return false;
      }
      return true;
    },
    Component: (exp) => {
      const { onlyPreschool } = exp;
      return (
        <>
          {!!(onlyPreschool) && (
          <ValueSelector
            id="initCost"
            subtitle="En nivel maternal, inicial - De 1 a 5 años"
            options={onResolveOptions('costs.initCost', optionData, exp)}
            type="single"
            onChange={(value) => onChangeStepValue('costs', { initCost: value }, exp, true)}
          />
          )}
          {true && (
          <ValueSelector
            id="schoolCost"
            subtitle="De primaria en adelante - De 6 a 17 años"
            options={onResolveOptions('costs.schoolCost', optionData, exp)}
            type="single"
            info={onlyPreschool ? 'Te hacemos esta pregunta porque también tendremos en cuenta centros que incluyan primaria.' : null}
            infoPlacing="top"
            onChange={(value) => onChangeStepValue('costs', { schoolCost: value }, exp, true)}
          />
          )}
        </>
      );
    },
  },
  {
    id: 'address',
    header,
    title: '¿En qué zona estás buscando?',
    subtitle: 'Ingresá una dirección para usar como centro de tu búsqueda.',
    valid: (exp) => exp?.address?.range && exp?.address?.latitude && exp?.address?.longitude,
    Component: (exp) => (
      <AddressSelector
        key="address"
        onChange={(value) => onChangeStepValue('address', value, exp, true)}
          // eslint-disable-next-line react/destructuring-assignment
        current={exp.address}
      />
    ),
  },
]);

const searchProfileStep = (optionData, onChangeStepValue) => ({
  id: 'searchProfile',
  title: 'Perfil de búsqueda',
  pages: searchProfileStepPages(optionData, onChangeStepValue),
});

export default searchProfileStep;
